<template>
  <li class="nav-item nav-search">

    <!-- Icon -->
    <a
      href="javascript:void(0)"
      class="nav-link nav-link-search"
      @click="showSearchBar = true"
    >
      <feather-icon
        icon="SearchIcon"
        size="21"
      />
    </a>

    <!-- Input -->
    <div
      class="search-input"
      :class="{'open': showSearchBar}"
    >
      <div class="search-input-icon">
        <feather-icon icon="SearchIcon" />
      </div>
      <b-form-input
        v-if="showSearchBar"
        v-model="searchQuery"
        placeholder="Buscar pacientes..."
        autofocus
        debounce="800"
        autocomplete="off"
        @keyup.esc="showSearchBar = false; resetsearchQuery()"
        @keyup.enter="suggestionSelected"
        @blur.stop="showSearchBar = false; resetsearchQuery()"
      />
      <div
        class="search-input-close"
        @click="showSearchBar = false; resetsearchQuery()"
      >
        <feather-icon icon="XIcon" />
      </div>

      <!-- Suggestions List -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="search-list search-list-main scroll-area overflow-hidden"
        :class="{'show': searchQuery}"
        tagname="ul"
      >
        <b-overlay
          variant="white"
          :show="showLoading"
          spinner-variant="primary"
          blur="0"
          opacity=".75"
          rounded="sm"
        >
          <p class="suggestion-group-title">
            <span>Pacientes</span>
          </p>

          <ul>
            <li
              v-for="(user, index) in filteredData"
              :key="index"
              class="suggestion-group-suggestion cursor-pointer"
              :class="{'suggestion-current-selected': currentSelected === `${index}`}"
              @mouseenter="currentSelected = `${index}`"
              @mousedown.prevent="suggestionSelected(user)"
            >
              <template>
                <div class="d-flex align-items-center">
                  <b-avatar
                    :src="user.photo ? user.photo.url : null"
                    class="mr-1"
                    size="32"
                  />
                  <div>
                    <p>{{ user.name }}</p>
                    <small>{{ user.email }}</small>
                  </div>
                  <small class="ml-auto">{{ user.code }}</small>
                </div>
              </template>
            </li>

            <li
              v-if="!filteredData.length && searchQuery"
              class="suggestion-group-suggestion no-results"
            >
              <p>Nenhum resultado encontrado.</p>
            </li>
          </ul>
        </b-overlay>
      </vue-perfect-scrollbar>
    </div>
  </li>
</template>

<script>
import {
  BFormInput, BAvatar, BOverlay,

} from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import useAutoSuggest from '@core/components/app-auto-suggest/useAutoSuggest'
import router from '@/router'
import store from '@/store'

export default {
  components: {
    BFormInput,
    BAvatar,
    BOverlay,

    VuePerfectScrollbar,
  },
  setup() {
    const showSearchBar = ref(false)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    const suggestionSelected = user => {
      router.push({ name: 'apps-patients-edit', params: { id: user.id } }).catch(() => { })
      // eslint-disable-next-line no-use-before-define
      resetsearchQuery()
      showSearchBar.value = false
    }

    const {
      searchQuery,
      showLoading,
      resetsearchQuery,
      filteredData,
    } = useAutoSuggest()

    watch(searchQuery, val => {
      store.commit('app/TOGGLE_OVERLAY', Boolean(val))
    })

    const currentSelected = ref(-1)

    return {
      showSearchBar,
      showLoading,
      perfectScrollbarSettings,
      currentSelected,
      suggestionSelected,

      // AutoSuggest
      searchQuery,
      resetsearchQuery,
      filteredData,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

ul
{
  list-style: none;
  padding: 0;
  margin: 0;
}
p {
  margin: 0;
}

/* .app-auto-suggest {
  position: relative;
}

.auto-suggest-suggestions-list {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  border-radius: 6px;
  position: absolute;
  top: calc(100% + 1rem);
  background-color: white;
  width: 100%;
} */

.suggestion-group-title {
  font-weight: 500;
  padding: .75rem 1rem .25rem;
}

.suggestion-group-suggestion {
  padding: .75rem 1rem
}

.suggestion-current-selected {
  background-color: $body-bg;

  .dark-layout & {
    background-color: $theme-dark-body-bg;
  }
}
</style>
