export default [
  {
    header: 'Forms & Tables',
    icon: 'EditIcon',
    children: [
      {
        title: 'Forms Elements',
        icon: 'CopyIcon',
        children: [
          {
            title: 'Checkbox',
            route: 'forms-element-checkbox',
          },
          {
            title: 'Radio',
            route: 'forms-element-radio',
          },
          {
            title: 'Switch',
            route: 'forms-element-switch',
          },
          {
            title: 'Date Time Picker',
            route: 'extensions-date-time-picker',
          },
          {
            title: 'Form Rating',
            route: 'forms-element-rating',
          },
        ],
      },
    ],
  },
]
