export default [
  {
    header: 'Apps',
    icon: 'PackageIcon',
    children: [
      {
        title: 'Chat',
        route: 'apps-chat',
        icon: 'MessageSquareIcon',
      },
      {
        title: 'Todo',
        route: 'apps-todo',
        icon: 'CheckSquareIcon',
      },
      // {
      //   title: 'Calendar',
      //   route: 'apps-calendar',
      //   icon: 'CalendarIcon',
      // },
    ],
  },
]
