export default [
  {
    title: 'Reception',
    icon: 'UserIcon',
    children: [
      {
        title: 'Schedules',
        route: 'apps-schedules',
        icon: 'CalendarIcon',
      },
      // {
      //   title: 'Schedules2',
      //   route: 'schedules2',
      //   icon: 'CalendarIcon',
      // },
      // {
      //   title: 'Calendar',
      //   route: 'apps-calendar',
      //   icon: 'CalendarIcon',
      // },
      {
        title: 'Patients',
        route: 'apps-patient-list',
        icon: 'UsersIcon',
      },
    ],
  },
  {
    title: 'Finances',
    icon: 'DollarSignIcon',
    children: [
      {
        title: 'Cashier',
        route: 'general-cashier',
        icon: '',
      },
      {
        title: 'Bills to Pay',
        route: 'apps-finances-bills-to-pay',
        icon: '',
      },
      {
        title: 'Bills to Receive',
        route: 'apps-finances-bills-to-receive',
        icon: '',
      },
      {
        title: 'Account Plans',
        route: 'finances-account-plans',
        icon: '',
      },
      {
        title: 'Dental Plans',
        route: 'dental-plans',
        icon: '',
      },
    ],
  },
  {
    title: 'Records',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Providers',
        route: 'providers',
        icon: '',
      },
      {
        title: 'Price List',
        route: 'price-list',
        icon: '',
      },

      // TODO:
      // NÃO ESTAMOS MAIS USANDO PROCEDIMENTOS DIRETAMENTE. COM AS NOVAS TABELAS DE PREÇO OS PROCEDIMENTOS SÃO EDITADOS E CADASTRADOS E EXLUÍDOS POR ALI
      // NO FUTURO CASO SE CONSOLIDE ISSO DEVEMOS EXCLUIR OS ARQUIVOS DE @/views/apps/procedures/list/ LEVANDO EM CONSIDERAÇÃO O FATO DE QUE
      // AS CATEGORIAS DE PROCEDIMENTOS QUE ESTÃO AÍ DENTRO DEVEM SER MANTIDAS OU ALTERADAS DE LOCAL POIS ESTÃO SENDO UTILIZADAS NOS LOCAIS DE LISTAGEM DE PROCEDIMENTO E FILTRO POR CATEGORIA
      // {
      //   title: 'Procedures',
      //   route: 'procedures-list',
      //   icon: '',
      // },
      {
        title: 'Health Insurances',
        route: 'health-insurances-list',
        icon: '',
      },
      {
        title: 'Anamnese Models',
        route: 'anamnese-models-list',
        icon: '',
      },
      {
        title: 'Dentists',
        route: 'dentists-list',
        icon: '',
      },
      {
        title: 'Documents',
        route: 'document-templates-list',
        icon: '',
      },
    ],
  },
]
