export default [
  {
    header: 'User Interface',
  },
  {
    title: 'Components',
    icon: 'ArchiveIcon',
    children: [
      {
        title: 'Popover',
        route: 'components-popover',
      },
      {
        title: 'Progress',
        route: 'components-progress',
      },
      {
        title: 'Tooltip',
        route: 'components-tooltip',
      },
    ],
  },
  {
    title: 'Extensions',
    icon: 'PlusCircleIcon',
    children: [
      {
        title: 'Tour',
        route: 'extensions-tour',
      },
    ],
  },
]
