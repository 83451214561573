import { ref, watch } from '@vue/composition-api'
import axios from '@axios'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useAutoSuggest() {
  const toast = useToast()
  const filteredData = ref({})
  const showLoading = ref(false)

  const searchQuery = ref('')
  const resetsearchQuery = () => {
    searchQuery.value = ''
  }

  const showToast = (variant = 'success', icon = 'CheckIcon', titleToast = 'Sucesso', text = 'Dados Salvos com Sucesso.') => {
    toast({
      component: ToastificationContent,
      props: {
        titleToast,
        icon,
        text,
        variant,
      },
    })
  }

  const catchErr = e => {
    if (e.response && (e.response.status === 412 || e.response.status === 422)) showToast('warning', 'AlertCircleIcon', 'Atenção', e.response.data.message)
    else if (e.response.status === 401) showToast('danger', 'AlertCircleIcon', 'Atenção', 'Sua sessão expirou, faça novo login!')
    else showToast('danger', 'AlertCircleIcon', 'Atenção', 'Desculpe... Algo não saiu como esperado.')
  }

  const handleSearchQueryUpdate = async val => {
    try {
      if (val === '') {
        filteredData.value = {}
      } else {
        showLoading.value = true
        const queriedData = await axios.post(`${process.env.VUE_APP_API_URL}/patient/search`, { search: val })
        filteredData.value = queriedData.data
        showLoading.value = false
      }
    } catch (e) {
      catchErr(e)
    }
  }

  watch(searchQuery, val => handleSearchQueryUpdate(val))

  return {
    searchQuery,
    resetsearchQuery,
    filteredData,
    showLoading,
  }
}
