/* eslint-disable */
// prettier-ignore
// TODO as pages com bookmaeked aparecem no topo da página. É preciso verificar quais ainda existem e se são necessárias removendo as não ncessárias
export default {
  pages: {
    key: 'title',
    data: [
      { title: 'Configurações', route: { name: 'settings' }, icon: 'SettingsIcon', isBookmarked: true },
      { title: 'Apex Chart', route: { name: 'charts-apex-chart' }, icon: 'PieChartIcon', isBookmarked: false },
      { title: 'Agendas', route: { name: 'apps-schedules' }, icon: 'CalendarIcon', isBookmarked: true },
      { title: 'Chartjs', route: { name: 'charts-chartjs' }, icon: 'PieChartIcon', isBookmarked: false },
      { title: 'Chat', route: { name: 'apps-chat' }, icon: 'MessageSquareIcon', isBookmarked: true },
      { title: 'Checkbox', route: { name: 'forms-element-checkbox' }, icon: 'CheckSquareIcon', isBookmarked: false },
      { title: 'Date Time Picker', route: { name: 'extensions-date-time-picker' }, icon: 'ClockIcon', isBookmarked: false },
      { title: 'Echart', route: { name: 'charts-echart' }, icon: 'PieChartIcon', isBookmarked: false },
      { title: 'Error 404', route: { name: 'error-404' }, icon: 'AlertTriangleIcon', isBookmarked: false },
      { title: 'FAQ', route: { name: 'pages-faq' }, icon: 'HelpCircleIcon', isBookmarked: false },
      { title: 'Financeiro', route: { name: 'general-cashier' }, icon: 'DollarSignIcon', isBookmarked: true },
      { title: 'Form Rating', route: { name: 'forms-element-rating' }, icon: 'StarIcon', isBookmarked: false },
      { title: 'Knowledge Base Category', route: { name: 'pages-knowledge-base-category' }, icon: 'InfoIcon', isBookmarked: false, },
      { title: 'Knowledge Base Question', route: { name: 'pages-knowledge-base-question' }, icon: 'InfoIcon', isBookmarked: false, },
      { title: 'Knowledge Base', route: { name: 'pages-knowledge-base' }, icon: 'InfoIcon', isBookmarked: false },
      { title: 'Popover', route: { name: 'components-popover' }, icon: 'TagIcon', isBookmarked: false },
      { title: 'Pricing', route: { name: 'pages-pricing' }, icon: 'DollarSignIcon', isBookmarked: false },
      { title: 'Progress', route: { name: 'components-progress' }, icon: 'ChevronsRightIcon', isBookmarked: false },
      { title: 'Radio', route: { name: 'forms-element-radio' }, icon: 'DiscIcon', isBookmarked: false },
      { title: 'Switch', route: { name: 'forms-element-switch' }, icon: 'ToggleRightIcon', isBookmarked: false },
      { title: 'Todo', route: { name: 'apps-todo' }, icon: 'CheckSquareIcon', isBookmarked: false },
      { title: 'Tooltip', route: { name: 'components-tooltip' }, icon: 'CopyIcon', isBookmarked: false },
      { title: 'Tour', route: { name: 'extensions-tour' }, icon: 'GlobeIcon', isBookmarked: false },
      { title: 'Tree', route: { name: 'extensions-tree' }, icon: 'GitCommitIcon', isBookmarked: false },
      { title: 'Typography', route: { name: 'ui-typography' }, icon: 'TypeIcon', isBookmarked: false },
      { title: 'Under Maintenance', route: { name: 'misc-under-maintenance' }, icon: 'MonitorIcon', isBookmarked: false },
    ],
  },
}
/* eslint-enable */
